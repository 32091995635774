
import React from 'react';

function Home() {
  return (
    <div>
      <section class="section_fv">
        <div class="fv_contents">
          <img src="/assets/img/fv.svg" />
        </div>
      </section>

      <section class="section_sns">
        <div class="sns_contents w-800 grid-8 m-grid-4 gap-2rem m-gap-1rem">
          {/* <a href="https://x.com/sasakiyusukenet" class="x" target="_blank">
            <img src="/assets/img/icons/x.svg" />
          </a> */}

          <a href="https://cluster.mu/u/sasakiyusukenet" class="cluster" target="_blank">
          <img src="/assets/img/icons/cluster.svg" />
          </a>

          <a href="https://radiotalk.jp/profile/890937" class="radiotalk" target="_blank">
          <img src="/assets/img/icons/radiotalk.svg" />
          </a>

          {/* <a href="https://www.spooncast.net/jp/profile/316493488" class="spoon" target="_blank">
          <img src="/assets/img/icons/spoon.svg" />
          </a> */}

          <a href="https://www.twitch.tv/sasakiyusukenet" class="twitch" target="_blank">
          <img src="/assets/img/icons/twitch.svg" />
          </a>

          {/* <a href="https://www.instagram.com/sasakiyusukenet/" class="instagram" target="_blank">
          <img src="/assets/img/icons/instagram.svg" />
          </a> */}

          {/* <a href="https://www.youtube.com/@sasakiyusukenet" class="youtube" target="_blank">
          <img src="/assets/img/icons/youtube.svg" />
          </a> */}

          <a href="https://www.amazon.co.jp/hz/wishlist/ls/27VRZEXGSRF" class="amazon" target="_blank">
          <img src="/assets/img/icons/amazon.svg" />
          </a>
        </div>
      </section>

      <section class="section_projects w-800">
        <div class="projects">

          <div class="content">
            <div class="content_text">
              <div class="content_title">
                SOZOLABO
              </div>
              <div class="content_title_kana">ソウゾウラボ</div>
              <div class="content_links">
                <a href="https://sozolabo.com" target="_blank"><i class="fa-solid fa-link"></i> https://sozolabo.com/</a>
              </div>
            </div>
          </div>

          <div class="content">
            <div class="content_text">
              <div class="content_title">
                SOZOCOMPANY
              </div>
              <div class="content_title_kana">ソウゾウカンパニー</div>
              <div class="content_links">
                <a href="https://sozocompany.jp" target="_blank"><i class="fa-solid fa-link"></i> https://sozocompany.jp/</a>
              </div>
            </div>
          </div>

          {/* <div class="content">
            <div class="content_text">
              <div class="content_title">
                SOZOSHOP
              </div>
              <div class="content_title_kana">ソウゾウショップ</div>
              <div class="content_links">
                <a href="https://sozoshop.booth.pm/" target="_blank">BOOTH支店</a>
              </div>
            </div>
          </div> */}

          {/* <div class="content y">
            <div class="content_text">
              <div class="content_title">YUSUKE SASAKI</div>
              <div class="content_description">
                <p>表現・創作</p>
              </div>
              <div class="content_links">
                <a href="https://yusukesasaki.net/" target="_blank"><span class="material-symbols-outlined">language</span></a>
                <a href="https://x.com/yusukesasaki/" target="_blank"><i class="fa-brands fa-x-twitter"></i></a>
                準備中
              </div>
            </div>
          </div> */}

        </div>
      </section>
    
      {/* <section class="section_logs w-800">
        <h2>
          MEMO
        </h2>
        <div class="grid-2 s-grid gap-2rem s-gap-1rem">
          <a href="https://booklog.jp/users/sasakiyusukenet" class="content" target="_blank">
            <div class="content_image"><span class="material-symbols-outlined">book_4</span></div>
            <div class="content_title">読書</div>
          </a>
          <a href="https://filmarks.com/users/sasakiyusukenet" class="content" target="_blank">
            <div class="content_image"><span class="material-symbols-outlined">movie</span></div>
            <div class="content_title">映像鑑賞</div>
          </a>
          <a href="/awards" class="content">
            <div class="content_image"><span class="material-symbols-outlined">trophy</span></div>
            <div class="content_title">受賞・入選</div>
          </a>
          <a href="/certifications" class="content">
            <div class="content_image"><span class="material-symbols-outlined">workspace_premium</span></div>
            <div class="content_title">資格・認定</div>
          </a>
        </div>
      </section> */}
      
      <section class="section_memo w-800">
        <h2>
          Memo
        </h2>
        <div class="grid-8 m-grid-4 gap-2rem m-gap-1rem">
          <a href="https://booklog.jp/users/sasakiyusukenet" class="content" target="_blank">
            <div class="content_image"><span class="material-symbols-outlined">book_4</span></div>
            <div class="content_title">読書</div>
          </a>
          <a href="https://filmarks.com/users/sasakiyusukenet" class="content" target="_blank">
            <div class="content_image"><span class="material-symbols-outlined">movie</span></div>
            <div class="content_title">映像鑑賞</div>
          </a>
          <a href="/awards" class="content">
            <div class="content_image"><span class="material-symbols-outlined">trophy</span></div>
            <div class="content_title">受賞・入選</div>
          </a>
          {/* <a href="/certifications" class="content">
            <div class="content_image"><span class="material-symbols-outlined">workspace_premium</span></div>
            <div class="content_title">資格・認定</div>
          </a> */}
        </div>
      </section>
    </div>
  );
}

export default Home;